<script setup>
import SearchBar from "@/Components/SearchBar.vue";
import ListItem from "@/Components/List/ListItem.vue"

const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
  type: {
    type: String,
    required: false,
  }
})

const emit = defineEmits(['search']);

// Handler function for input changes
const handleSearch = (searchText) => {
  emit('search', searchText);
};
</script>

<template>
<div class="flex flex-col max-h-screen max-w-full sm:bg-finexpal-gray-100 w-[40rem]">
<!--  Todo: emit search string on change-->
  <SearchBar class="hidden sm:flex" @search="handleSearch"></SearchBar>
  <div class="max-h-full mt-4 overflow-auto">
    <RouterLink v-if="type === 'expense'" v-for="item in props.data" :to="`expenses/${item.id}`">
      <ListItem  :name="item.name" :category="item.categories[0].name" :quantity="item.quantity" :date="item.date" :colour="item.categories[0].colour"/>
    </RouterLink>
    <RouterLink v-else-if="type === 'income'" v-for="item in props.data" :to="`incomes/${item.id}`">
      <ListItem  :name="item.name" :category="item.section.name" :quantity="item.quantity" :date="item.date" :colour="item.section.colour"/>
    </RouterLink>
    <RouterLink v-else-if="type === 'section'" v-for="item in props.data" :to="`accounts/${item.id}`">
      <ListItem  :name="item.name" :quantity="item.quantity" :date="item.date" :colour="item.colour"/>
    </RouterLink>
  </div>
</div>
</template>

<style scoped>

</style>