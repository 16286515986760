<script setup>
import AuthenticatedLayout from "@/Layouts/AuthenticatedLayout.vue"
import Tab from "@/Components/Default/Tab.vue"
import DateRangeSelector from "@/Components/Default/DateRangeSelector.vue"
import DateSelector from "@/Components/Default/DateSelector.vue"
import AddButton from "@/Components/Default/AddButton.vue"
import List from "@/Components/List/List.vue"
import Chart from "@/Components/Chart/Chart.vue"

import {onBeforeMount, onMounted, onUnmounted, ref, h, render, watch} from "vue";
import {HTTP} from "@/Plugins/axios";
import { useRouter } from "vue-router";

const API_URL = process.env.VUE_APP_API_URL;
const router = useRouter();

const expenses = ref();
const chartContainer = ref(null);
const categories = ref();
const colours = ref();
const incomes = ref();
const tabSelected = ref('');
const endDate = ref(new Date());
const startDate = ref(new Date(new Date().setDate(endDate.value.getDate() - 7)));
const dateRangeSelected = ref('week');
const total = ref(0);

const chartData = ref({
  labels: [],
  datasets: []
});

const selectExpenses = () => {
  if (tabSelected.value !== 'expense') {
    tabSelected.value = 'expense';
    getExpenses()
    getChartData()
  }
}

const getStartDate = () => {
  let date = startDate.value.toLocaleDateString().split('/')
  return date[2]+'-'+date[1]+'-'+date[0]
}

const getEndDate = () => {
  let date = endDate.value.toLocaleDateString().split('/')
  return date[2]+'-'+date[1]+'-'+date[0]
}

const getExpenses = () => {
  HTTP({
    url: `${API_URL}expenses?startDate=${getStartDate()}&endDate=${getEndDate()}`,
    method: 'GET',
    data: {
      start_date: startDate.value,
      end_date: endDate.value,
    },
    headers: {
      Authorization:
          typeof localStorage !== 'undefined'
              ? localStorage.getItem('jwt')
                  ? `Bearer ${localStorage.getItem('jwt')}`
                  : ''
              : '',
    },
  })
      .then((response) => {
        expenses.value = response.data.data.expenses;
        categories.value = response.data.data.categories;
        colours.value = response.data.data.colours;
        total.value = response.data.data.total;
      })
      .catch((err) => {})
}

const selectIncomes = () => {
  if (tabSelected.value !== 'income') {
    tabSelected.value = 'income';
    getIncomes()
    getChartData()
  }
}

const getIncomes = () => {
  HTTP({
    url: `${API_URL}incomes?startDate=${getStartDate()}&endDate=${getEndDate()}`,
    method: 'GET',
    data: {
      start_date: startDate.value,
      end_date: endDate.value,
    },
  })
      .then((response) => {
        incomes.value = response.data.data.incomes;
        categories.value = response.data.data.sections;
        colours.value = response.data.data.colours;
        total.value = response.data.data.total;
      })
      .catch((err) => {})
}

const changeDateRange = (range) => {
  //Set date range
  let date = new Date()
  let offset = date.getTimezoneOffset()
  date = new Date(date.getTime() - (offset*60*1000))
  dateRangeSelected.value = range
  let today = new Date(date).getDate()
  switch (range) {
    case 'day': {
      startDate.value = new Date(new Date().setDate(today))
      endDate.value = startDate.value
      break
    }
    case 'week': {
      let currentDay = date.getDay()
      startDate.value = new Date(new Date().setDate(today - (currentDay || 6)))
      endDate.value = new Date(new Date().setDate(today - currentDay + 7))
      break
    }
    case 'month': {
      startDate.value = new Date(date.getFullYear(), date.getMonth(), 1);
      endDate.value = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      break
    }
    case 'year': {
      startDate.value = new Date(date.getFullYear(), 0, 1);
      endDate.value = new Date(date.getFullYear(), 12, 0);
      break
    }
  }
}

const updateDates = (newStartDate, newEndDate) => {
  startDate.value = newStartDate
  endDate.value = newEndDate

  if (tabSelected.value === 'incomes') {
    getIncomes()
  } else {
    getExpenses()
  }
  getChartData()
}

const getChartData = () => {
  HTTP({
    url: `${API_URL}chart/${tabSelected.value}s?startDate=${getStartDate()}&endDate=${getEndDate()}&needed=true`,
    method: 'GET',
    data: {
      start_date: startDate,
      end_date: endDate,
    },
    headers: {
      Authorization:
          typeof localStorage !== 'undefined'
              ? localStorage.getItem('jwt')
                  ? `Bearer ${localStorage.getItem('jwt')}`
                  : ''
              : '',
    },
  })
      .then((response) => {
        chartData.value['labels'] = response.data.data.labels
        total.value = response.data.data.total;
        let data = {
          data: response.data.data.values,
          backgroundColor: response.data.data.colours
        }
        chartData.value['datasets'] =[data]
      })
      .catch((err) => {
        console.log(err);
      })
}

onBeforeMount(() => {
  selectExpenses()
})

onMounted((total) => {
  getChartData();
  console.log(total.value);
  const createDynamicCharts = () => {
    // Create a main wrapper element dynamically
    const wrapper = document.createElement('div');
    const chart_container = document.querySelector('#chartContainer') || document.body;
    wrapper.className = 'chart-wrapper w-full h-[35rem]';
    chart_container.appendChild(wrapper); // Or append to any other parent element

    const vnode = h('div',
        {
          class: 'flex-1 w-full mb-4 h-full'
        },
        [
          h('div',
              {
                class: 'flex justify-center mt-4 font-bold text-3xl'
              },
              `Total: ${total.value}`
          ),
          h(Chart, {
            data: chartData.value,
            chartType: 'doughnut',
            class: '!h-full'
          })
        ]
    );

    // Render to our dynamically created wrapper
    render(vnode, wrapper);

    // Return wrapper reference for cleanup
    return wrapper;
  };

  // Store reference to clean up later
  const wrapperRef = createDynamicCharts();

  // Watch for changes
  // watch(chartData, () => {
  //   // Clean up previous render
  //   // render(null, wrapperRef);
  //   wrapperRef.remove();
  //   createDynamicCharts();
  // }, { deep: true });

  // Clean up on component unmount
});
onUnmounted(() => {
  if (wrapperRef) {
    render(null, wrapperRef);
    wrapperRef.remove();
  }
});
</script>

<template>
  <AuthenticatedLayout>
    <div class="flex flex-col bg-finexpal-gray-300 w-full">
      <div class="flex justify-center mt-4 font-bold text-3xl">
        $ {{ total }}
      </div>
      <div class="flex justify-center gap-x-6 mt-4">
        <tab id="expenses" @click.prevent="selectExpenses" :class="[tabSelected === 'expense' ? 'bg-finexpal-gray-600' : '']">
          Expenses
        </tab>
        <tab id="incomes" @click.prevent="selectIncomes" :class="[tabSelected === 'income' ? 'bg-finexpal-gray-600' : '']">
          Incomes
        </tab>
      </div>
      <div class="flex flex-col sm:flex-row justify-between items-center px-8 py-4">
        <DateRangeSelector :date-range-selected="dateRangeSelected" @changed-date-range-selected="changeDateRange"/>
        <DateSelector :range="dateRangeSelected" @update-dates="updateDates" :start-date="startDate" :end-date="endDate"/>
        <div class="w-[8rem] pl-4">
          <RouterLink v-if="tabSelected === 'expense'" to="/expenses/create">
            <AddButton />
          </RouterLink>
          <RouterLink v-else to="/incomes/create">
            <AddButton />
          </RouterLink>
        </div>
      </div>
      <div id="chartContainer" ref="chartContainer" class="grid grid-cols-1 sm:grid-cols-2 justify-items-center gap-y-8 h-full overflow-y-auto w-full no-scrollbar"></div>
    </div>
<!--    <List v-if="tabSelected === 'expense'"  :data="expenses" type="expense"/>-->
<!--    <List v-else :data="incomes" type="income"/>-->
  </AuthenticatedLayout>
</template>