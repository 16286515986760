<script setup>
import Magnify   from 'vue-material-design-icons/Magnify.vue';
import FilterOutline    from 'vue-material-design-icons/FilterOutline.vue';
import InputText from "primevue/inputtext";
import {ref} from "vue";

const searchText = ref("");

// Define the emits
const emit = defineEmits(['search']);

// Handler function for input changes
const handleSearch = () => {
  emit('search', searchText.value);
};
</script>

<template>
<div class="flex flex-row align-middle h-12	mt-4">
  <div class="flex items-center bg-finexpal-gray-300 w-[22rem] h-10 max-w-[22rem] ml-4 mr-3 rounded-full">
    <Magnify class="ml-4"/>
    <InputText
        v-model="searchText"
        class="!bg-finexpal-gray-300 !rounded-none !h-full !outline-0 !shadow-none ml-1 w-full !rounded-r-full"
        @input="handleSearch"
    ></InputText>
  </div>
  <div class="flex items-center h-10">
    <button >
      <FilterOutline size="30"/>
    </button>
  </div>
</div>
</template>

<style scoped>

</style>