<script setup>
import Chart from 'primevue/chart';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ref,computed, watch} from "vue";

const props = defineProps({
  labels: {
    type: Array,
    required: false
  },
  data: {
    type: Array,
    required: false
  },
  colours: {
    type: Array,
    required: false
  },
  chartType: {
    type: String,
    required: true
  }
})

const myChart = ref();

const chartLabels = computed(() => {
  return props.labels
})

const chartData = computed(() => {
  return props.data
})

const chartColours = computed(() => {
  return props.colours
})

const setChartOptions =  {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltips: {
        enabled: true
      },
      legend: {
        position: 'bottom',
        cutout: '60%',
      },
      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map(data => {
            sum += data;
          });
          return (value*100 / sum).toFixed(2)+"%";
        },
        color: '#fff',
      }
    }
}

watch(props, () => {

  console.log(myChart.value.data)
})
</script>

<template>
  <div class="card flex justify-center w-full h-1/2">
<!--    <canvas ></canvas>-->
    <Chart ref="myChart" :type="props.chartType" :data="chartData" :options="setChartOptions" :plugins="[ChartDataLabels]" class="sm:w-2/3 h-full"/>
  </div>

</template>

<style scoped>

</style>